//
// VARIABLES
//

// Colors
$blue: #4183c4;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$mediumLightGray: #ccc;
$lightGray: #eee;
$white: #fff;

$backgroundColor: #fcfcfc;

$border-color: rgba(60, 60, 60, 0.33);
$footer-color: rgba(60, 60, 60, 0.12);
$link-color: $darkerGray;
$link-color-hover: #55a0de;

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$sansSerif: Lato, "Museo Sans", "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
