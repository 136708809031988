/* if you add fonts here, add <link rel="preload">  tags for them toapp/views/layouts/web_client.html.haml */
// @font-face {
//   font-family: "Rubik";
//   src: url("fonts/Rubik-300.woff") format("woff2");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Rubik";
//   src: url("fonts/Rubik-500.woff") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Rubik";
//   src: url("fonts/Rubik-700.woff") format("woff2");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Rubik";
//   src: url("fonts/Rubik-900.woff") format("woff2");
//   font-weight: 900;
//   font-style: normal;
// }

/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/Lato-Regular.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Lato";
  font-style: bold;
  font-weight: 700;
  src: url("/assets/Lato-Bold.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
